/* eslint-disable react/no-multi-comp */
import {
  Avatar,
  Box,
  Chip,
  Grid,
  SvgIconTypeMap,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { ArrowForward } from "@material-ui/icons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AnyObject } from "@nvon/baseline";
import { important } from "@nvon/react-toolbox";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { graphql } from "gatsby";
import { Link } from "gatsby-material-ui-components/lib/link";
import { Seo } from "gatsby-plugin-wpgraphql-seo";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CustomArrowProps, Settings } from "react-slick";
import { useLocation } from "react-use";
import { Page } from "../../../../config/Pages";
import {
  PostTypeInProject,
  Template,
} from "../../../../config/wordPressConfig";
import { dateFormatLong } from "../../../helpers/clientSide/constants";
import {
  mapCustomGutenbergBlocks,
  mapGenericContent,
} from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/mappings/htmlTagsMappedToReactComponents";
import { replaceHtmlWithFunctionComponent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import { pathForSingleUser } from "../../../helpers/serverSide/pageCreation/pathForSingleUser";
import { useCurrentMuiBreakpoint } from "../../../hooks/useCurrentMuiBreakpoint";
import { User } from "../../../interfaces/User";
import theme from "../../../layouts/theme";
import { usePageForTemplate } from "../../../queries/wpPage/usePageForTemplate";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import { mapSingleWpPostToPost } from "../../../queries/wpPost/mapSingleWpPostToPost";
import { useSimilarPosts } from "../../../queries/wpPost/useSimilarPosts";
import { useWpUserById } from "../../../queries/wpUser/useWpUserById";
import FeaturedVideo from "../../01-atoms/FeaturedVideo/FeaturedVideo";
import HyphenatedTypography from "../../01-atoms/HyphenatedTypography/HyphenatedTypography";
import RVOArticleText from "../../01-atoms/RVOArticleText/RVOArticleText";
import { SanitizeHTML } from "../../01-atoms/SanitizeHTML/SanitzeHTML";
import WordPressImage from "../../01-atoms/WordPressImage/WordPressImage";
import RVOAuthorCard from "../../02-molecules/RVOAuthorCard/RVOAuthorCard";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import { RVOBackgroundGradient } from "../../02-molecules/RVOContainer/RVOContainerInterfaces";
import RVORelatedPostSlider from "../../02-molecules/RVORelatedPostSlider/RVORelatedPostSlider";
import VTSUnderlinedTitle from "../../02-molecules/VTSUnderlinedTitle/VTSUnderlinedTitle";
import { RVOFaqSection } from "../../03-organisms/RVOFaqSection/RVOFaqSection";
import RVORegisterTodayCtaSection from "../../03-organisms/RVORegisterTodayCtaSection/RVORegisterTodayCtaSection";
import { useRVOBlogDetailsTemplateStyles } from "./RVOBlogDetailsTemplateStyles";

const numberOfSlidesForBreakpoint: Record<Breakpoint, number> = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 3,
  xl: 3,
};

const isFundamentalPost = (
  postData: WpPost | WpFundamental
): postData is WpFundamental => {
  return "wpFundamental" in postData && postData.wpFundamental !== null;
};

/**
 * React click does weird stuff with the custom arrows.
 * We need to wrap them to avoid the invalid props warning.
 */
export const createSlickArrow = (
  Icon: OverridableComponent<SvgIconTypeMap<AnyObject, "svg">>
): ((props: CustomArrowProps) => JSX.Element) => {
  const ArrowComponent = (props: CustomArrowProps): JSX.Element => {
    return (
      <Icon
        className={props.className}
        style={props.style}
        onClick={props.onClick}
      />
    );
  };
  return ArrowComponent;
};

const NextArrow = createSlickArrow(ArrowForward);
const PrevArrow = createSlickArrow(ArrowBackIcon);

const defaults: Settings = {
  dots: true,
  infinite: false,
  arrows: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  speed: 500,
  autoplay: false,
  autoplaySpeed: 4000,
  accessibility: true,
  swipeToSlide: true,
  draggable: false,
};

const RVOBlogDetailsTemplate = ({
  data,
}: {
  data: WpPost | WpFundamental;
}): JSX.Element | null => {
  const classes = useRVOBlogDetailsTemplateStyles();
  const { t } = useTranslation();
  const breakpoint = useCurrentMuiBreakpoint(undefined);
  const settings = useMemo(
    () => ({
      ...defaults,
      slidesToShow: breakpoint ? numberOfSlidesForBreakpoint[breakpoint] : 1,
    }),
    [breakpoint]
  );

  const path = useLocation().pathname;

  const post = mapSingleWpPostToPost(
    isFundamentalPost(data) ? data.wpFundamental : data.wpPost
  );

  const similarPosts = useSimilarPosts(post);

  const author = post.author;
  const coAuthorId = post.coAuthorId;
  const coAuthor = useWpUserById(coAuthorId);
  const authors: User[] = [author]; // Specify the type of the authors array
  if (coAuthor) {
    authors.push(coAuthor as User); // Remove the unnecessary type assertion
  }

  const date = dayjs(post?.date).format(dateFormatLong);
  const blogTemplate = usePageForTemplate(Template.KnowledgeDatabase);
  const relatedArticleTitle = useSinglePage(blogTemplate?.id)?.pageFields
    ?.knowledgeDatabase?.relatedArticles?.title;
  const legalTypes = post.legalTypes;
  const featuredImage = post.image;
  const featuredVideo = post.video;
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const breakpointsAvailable = !!breakpoint;

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  /**
   * We're inserting an invisible space after slashes,
   *  so they can line-break properly.
   */
  const title = useMemo(
    () => post?.title?.replace("/", "/&#8203;") || "",
    [post?.title]
  );

  if (!author?.name || !author.avatar) {
    return null;
  }

  const getPagePathByUrl = (): string => {
    const isFundamentalPage = path?.includes(Page.Grundlagen);
    const isWissensPage = path?.includes(Page.Wissen);

    if (isFundamentalPage) {
      return Page.Grundlagen;
    }

    if (isWissensPage) {
      return Page.Wissen;
    }

    return Page.Home;
  };

  return (
    <>
      <Seo post={post} />

      <RVOContainer
        fullWidth={true}
        background={RVOBackgroundGradient.SecondaryLightToMediumBlue}
        paddingBottom={featuredVideo ? false : true}
      >
        <Box className={classes.arrowBack}>
          <Link color="primary" to={`/${getPagePathByUrl()}/`}>
            <ArrowBackIcon fontSize="large" />
          </Link>
        </Box>

        <Grid
          container={true}
          className={featuredVideo ? classes.headerWithVideo : classes.header}
        >
          {featuredVideo && (
            <FeaturedVideo
              schemaTitle={post?.title}
              schemaDescription={post?.excerpt}
              schemaUploadDate={post?.date}
              schemaContentUrl={featuredVideo.mediaItemUrl}
              schemaThumbnailUrl={featuredImage?.src}
              schemaRegionsAllowed="DE, AT, CH"
              videoUrl={featuredVideo.mediaItemUrl}
              posterImage={featuredImage?.src}
              playing={true}
            />
          )}

          {isMobile ? (
            <HyphenatedTypography variant="h1" className={classes.title}>
              {title}
            </HyphenatedTypography>
          ) : (
            <Typography variant="h1" className={classes.title}>
              <SanitizeHTML html={title} />
            </Typography>
          )}

          <Grid
            container={true}
            item={true}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              columnGap: theme.spacing(1),
              rowGap: theme.spacing(1),
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                flexWrap="wrap"
                style={{ gap: theme.spacing(2) }}
              >
                <Link
                  underline="none"
                  to={pathForSingleUser(PostTypeInProject.WpPost, author?.name)}
                  className={classes.authorNameLink}
                >
                  <motion.div
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.1 }}
                  >
                    <Chip
                      style={{
                        height: "64px",
                        borderRadius: important("64px"),
                        backgroundColor: theme.palette.background.default,
                        color: theme.palette.primary.dark,
                        fontWeight: theme.typography.fontWeightMedium,
                      }}
                      label={
                        author?.additionalInfo.jobTitle ? (
                          <Box display={"flex"} flexDirection={"column"}>
                            <Typography variant="body2">
                              {author?.name}
                            </Typography>

                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.primary.light }}
                            >
                              {author?.additionalInfo.jobTitle}
                            </Typography>
                          </Box>
                        ) : (
                          author?.name
                        )
                      }
                      avatar={
                        <Avatar
                          alt={author?.name}
                          src={author.avatar.url}
                          style={{ height: "48px", width: "48px" }}
                        />
                      }
                      className={classes.authorNameChip}
                    />
                  </motion.div>
                </Link>

                {coAuthor?.name && (
                  <Link
                    underline="none"
                    to={pathForSingleUser(
                      PostTypeInProject.WpPost,
                      coAuthor?.name
                    )}
                    className={classes.authorNameLink}
                  >
                    <motion.div
                      whileTap={{ scale: 0.9 }}
                      whileHover={{ scale: 1.1 }}
                    >
                      <Chip
                        style={{
                          height: "64px",
                          borderRadius: important("64px"),
                          backgroundColor: theme.palette.background.default,
                          color: theme.palette.primary.dark,
                          fontWeight: theme.typography.fontWeightMedium,
                        }}
                        label={
                          coAuthor?.additionalInfo?.jobTitle ? (
                            <Box display={"flex"} flexDirection={"column"}>
                              <Typography variant="body2">
                                {coAuthor?.name}
                              </Typography>

                              <Typography
                                variant="body2"
                                style={{ color: theme.palette.primary.light }}
                              >
                                {coAuthor?.additionalInfo.jobTitle}
                              </Typography>
                            </Box>
                          ) : (
                            coAuthor?.name
                          )
                        }
                        avatar={
                          <Avatar
                            alt={coAuthor?.name}
                            src={coAuthor?.avatar?.url}
                            style={{ height: "48px", width: "48px" }}
                          />
                        }
                        className={classes.authorNameChip}
                      />
                    </motion.div>
                  </Link>
                )}
              </Box>

              <Typography
                style={{ marginTop: theme.spacing(2), textAlign: "center" }}
                variant="body1"
              >{`${t("general.lastUpdated")}: ${date}`}</Typography>
            </Box>
          </Grid>
        </Grid>
      </RVOContainer>

      <RVOContainer
        noPaddingTop={!featuredImage || featuredVideo ? false : true}
        paddingBottom={false}
      >
        <Grid container={true} className={classes.imageWrapper}>
          {!featuredVideo && featuredImage && (
            <WordPressImage
              imageInformation={featuredImage}
              imageClass={classes.image}
            />
          )}
        </Grid>

        <RVOArticleText
          ctaMitarbeiterbeteiligung={post?.ctaMitarbeiterbeteiligung}
          ctaVereinsrecht={post?.ctaVereinsrecht}
          authors={authors}
          legalTypes={legalTypes}
        >
          {replaceHtmlWithFunctionComponent(
            post?.content,
            mapGenericContent,
            mapCustomGutenbergBlocks
          )}
        </RVOArticleText>
      </RVOContainer>

      <RVORegisterTodayCtaSection />

      <RVOContainer paddingBottom={true}>
        <Typography variant="body2" className={classes.authorHeading}>
          {t("wissen.authorHeading")}
        </Typography>

        {!coAuthor?.name ? (
          <RVOAuthorCard
            author={author}
            typographyProps={{ variant: "h5" }}
            showLink={true}
          />
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              gap: isSmallScreen ? theme.spacing(1) : theme.spacing(4),
              alignItems: isSmallScreen ? "center" : "flex-start",
              justifyContent: "center",
            }}
          >
            <RVOAuthorCard
              author={coAuthor as User}
              typographyProps={{ variant: "h5" }}
              showLink={true}
              noLateralMargin={true}
            />

            <RVOAuthorCard
              author={author}
              typographyProps={{ variant: "h5" }}
              showLink={true}
              noLateralMargin={true}
            />
          </Box>
        )}
      </RVOContainer>

      {post.faqs?.title && (
        <RVOFaqSection
          title={post.faqs.title}
          questions={post.faqs.questions}
        />
      )}

      {similarPosts.length > 0 && (
        <RVOContainer paddingBottom={true} fullWidth={true}>
          <VTSUnderlinedTitle
            titleRaw={relatedArticleTitle || ""}
            typographyProps={{ variant: "h2" }}
            component="h2"
          />

          <RVORelatedPostSlider
            settings={settings}
            breakpointsAvailable={breakpointsAvailable}
            similarPosts={similarPosts}
          />
        </RVOContainer>
      )}
    </>
  );
};

export type WpPost = { wpPost: GatsbyTypes.WpPostFieldsFragment };
export type WpFundamental = {
  wpFundamental: GatsbyTypes.WpFundamentalsFieldsFragment;
};

export const query = graphql`
  query GET_WP_POST_FIELDS($id: String!) {
    wpPost(id: { eq: $id }) {
      ...WpPostFields
    }
    wpFundamental(id: { eq: $id }) {
      ...WpFundamentalsFields
    }
  }
`;

export default RVOBlogDetailsTemplate;
