import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { containerPaddingBigDevices } from "../../../helpers/clientSide/constants";

export const useRVOBlogDetailsTemplateStyles = makeStyles((theme: Theme) =>
  createStyles({
    arrowBack: {
      position: "absolute",
      height: "100%",
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(-containerPaddingBigDevices),
      [theme.breakpoints.down("sm")]: {
        alignItems: "flex-start",
        marginTop: theme.spacing(-4),
      },
    },
    header: {
      rowGap: theme.spacing(2),
    },
    headerWithVideo: {
      rowGap: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    title: {
      textAlign: "center",
      width: "75%",
      margin: "0 auto",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    authorNameLink: {
      background: "transparent",
    },
    authorNameChip: {
      background: "white",
      cursor: "pointer",
    },
    imageWrapper: {
      justifyContent: "center",
      marginTop: theme.spacing(-5),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(-3),
      },
    },
    image: {
      borderRadius: theme.spacing(0.5),
      width: "80%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    authorHeading: {
      textAlign: "center",
      marginBottom: theme.spacing(1),
    },
  })
);
