import { useTheme } from "@material-ui/core";
import { filterNoUndefinedNoNull } from "@nvon/baseline";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import { RVOBackgroundColor } from "../../02-molecules/RVOContainer/RVOContainerInterfaces";
import VTSUnderlinedTitle from "../../02-molecules/VTSUnderlinedTitle/VTSUnderlinedTitle";
import { faqSection } from "../../03-organisms/NVONHeader/NVONPageSections/NVONPageSections";
import RVOFaqComponent from "../../03-organisms/RVOFaqComponent/RVOFaqComponent";

export interface RVOFaqSectionProps {
  title: string;
  questions: GatsbyTypes.WpQuestionFieldsFragment[];
}

export const RVOFaqSection = ({
  title,
  questions,
}: RVOFaqSectionProps): JSX.Element => {
  const faqs = (questions || []).filter(filterNoUndefinedNoNull);
  const theme = useTheme();
  const faqTitle = title;

  return (
    <RVOContainer
      fullWidth={true}
      background={RVOBackgroundColor.SecondaryLightBlue}
      paddingBottom={true}
      id={faqSection.sectionId}
    >
      <VTSUnderlinedTitle
        titleRaw={faqTitle || ""}
        typographyProps={{
          variant: "h1",
          style: {
            width: "100%",
            textAlign: "center",
            marginBottom: theme.spacing(6),
          },
        }}
        component="h2"
      />

      <RVOFaqComponent faqs={faqs} />
    </RVOContainer>
  );
};
