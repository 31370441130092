import { Box, Button, List, ListItem, useTheme } from "@material-ui/core";
import { useRVOTableOfContentsStyles } from "./RVOTableOfContentsStyles";
import { useHeadings } from "./useHeadings";
import { useScrollSpy } from "./useScrollSpy";

const RVOTableOfContents = (): JSX.Element => {
  const classes = useRVOTableOfContentsStyles();
  const headings = useHeadings();
  const activeId = useScrollSpy(
    headings.map(({ id }) => id),
    { rootMargin: "0% 0% -25% 0%" }
  );
  const theme = useTheme();

  return (
    <div>
      <Box component="nav">
        <List>
          {headings.map((heading) => (
            <ListItem
              key={heading.id}
              className={classes.listItem}
              style={{
                borderLeft:
                  activeId === heading.id
                    ? `2px solid ${theme.palette.primary.main}`
                    : "none",
              }}
            >
              <Button
                href={`#${heading.id}`}
                style={{
                  marginLeft: `${heading.level - 2}em`,
                  fontWeight: activeId === heading.id ? "bold" : "normal",
                  lineHeight: "1.4",
                }}
                variant="text"
                size="small"
              >
                {heading.text}
              </Button>
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
  );
};

export default RVOTableOfContents;
