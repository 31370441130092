import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useRVOTableOfContentsStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        overflowX: "visible !important",
      },
    },

    listItem: {
      paddingTop: theme.spacing(0.2),
      paddingBottom: theme.spacing(0.1),
    },
  })
);
