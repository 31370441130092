import { useAllUsers } from "./useAllUsers";

export const useWpUserById = (
  authorId: GatsbyTypes.Maybe<number>
): GatsbyTypes.WpUserFieldsFragment | undefined => {
  const allUsers = useAllUsers();

  const singleUser = allUsers.find(
    // This eslint-disable is used because the name of the author is not null
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

    ({ databaseId }) => databaseId === authorId
  );

  return singleUser;
};
