/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { BlogPost } from "../../interfaces/Blog";
import { mapSingleWpPostToPost } from "./mapSingleWpPostToPost";
import { useAllPosts } from "./useAllPosts";

export const useSimilarPosts = (blogPost: BlogPost): BlogPost[] => {
  const { slug, categories } = blogPost;
  const allPosts = useAllPosts();
  const mappedCategories: string =
    categories
      ?.map((cat) => {
        const even: string = cat?.name ?? "";
        const sub: string = cat?.wpParent?.node?.name ?? "";
        const subsub: string = cat?.wpParent?.node?.wpParent?.node?.name ?? "";
        const subsubsub: string =
          cat?.wpParent?.node?.wpParent?.node?.wpParent?.node?.name ?? "";
        const all: string = [even, sub, subsub, subsubsub].join(",");
        return all;
      })
      .join(",") || "";

  const postsExceptSlug = allPosts.filter((post) => {
    return post.slug !== slug;
  });
  const similarPosts = postsExceptSlug
    .filter((post) => {
      if (post.categories?.nodes !== undefined) {
        return post.categories?.nodes
          .map((category) => {
            return category?.name || "";
          })
          .some((r) => mappedCategories.indexOf(r) >= 0);
      }
      return [];
    })
    .slice(0, 9);
  const mappedPosts = similarPosts.map(
    (currentPostObject: GatsbyTypes.WpPostFieldsFragment) => {
      return mapSingleWpPostToPost(currentPostObject);
    }
  );
  return mappedPosts;
};
