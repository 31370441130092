import { Category } from "../../../interfaces/Blog";
import { TreeRoot } from "./RVOArticleSidebar";

export function makeCategoryTree(categories: Category[]): TreeRoot[] {
  const tree = categories.map((category) => {
    if (
      category.wpParent &&
      category.wpParent.node &&
      !category.wpParent.node.wpParent
    ) {
      return {
        ...category.wpParent.node,
        children: [
          { name: category.name, slug: category.slug, id: category.id },
        ],
      };
    }
    if (
      category.wpParent &&
      category.wpParent.node &&
      category.wpParent.node.wpParent &&
      !category.wpParent.node.wpParent.node.wpParent
    ) {
      return {
        ...category.wpParent.node.wpParent.node,
        children: [
          {
            name: category.wpParent.node.name,
            slug: (category.wpParent.node as { slug: string }).slug,
            id: category.wpParent.node.id,
            child: {
              name: category.name,
              slug: category.slug,
              id: category.id,
            },
          },
        ],
      };
    }
    return { name: category.name, slug: category.slug, id: category.id };
  });

  return tree as TreeRoot[];
}
