import { Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";
import {
  containerPaddingBigDevices,
  containerPaddingSmallDevicesTopBottom,
} from "../clientSide/constants";

export const rvoSliderStyles = (theme: Theme): CSSProperties => ({
  transition: theme.transitions.create(["opacity"], {
    duration: theme.transitions.duration.standard,
    easing: theme.transitions.easing.easeInOut,
  }),
  position: "initial",
  "& .slick-dots li button:before": {
    fontSize: theme.spacing(2),
  },
  "& .slick-dots": {
    position: "relative",
    bottom: theme.spacing(-1),
  },
  "& .slick-track": {
    display: "flex",
    height: "auto",
    flex: 1,
    marginLeft: 0,
  },
  "& .slick-slide": {
    height: "auto",
    padding: theme.spacing(0, 0.5),
    flex: 1,
    [theme.breakpoints.only("xs")]: {
      padding: 0,
    },
  },
  "& img.emoji": {
    display: "inline-block",
  },
  "& .slick-slide > div": {
    height: "100%",
    display: "flex",
    alignItems: "stretch",
  },
  "& .slick-disabled": {
    color: `${theme.palette.grey[200]} !important`,
    border: `1px solid ${theme.palette.grey[200]} !important`,
  },
  "& .slick-arrow": {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    padding: theme.spacing(0.5),
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  "& .slick-next": {
    right: theme.spacing(3),
    top: theme.spacing(containerPaddingBigDevices + 1),
    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(containerPaddingSmallDevicesTopBottom + 1),
    },
    [theme.breakpoints.only("xs")]: {
      right: theme.spacing(2),
    },
  },
  "& .slick-prev": {
    left: "initial",
    right: theme.spacing(6),
    top: theme.spacing(containerPaddingBigDevices + 1),
    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(containerPaddingSmallDevicesTopBottom + 1),
    },
    [theme.breakpoints.only("xs")]: {
      right: theme.spacing(5),
    },
  },
  "& .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus":
    {
      color: theme.palette.primary.main,
    },
});
