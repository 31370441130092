import {
  Avatar,
  Box,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { User } from "../../../interfaces/User";
import HtmlFromLangFile from "../HtmlFromLangFile/HtmlFromLangFile";
import RVOTypeformPopupButton from "../RVOTypeformPopupButton/RVOTypeformPopupButton";

interface RVOCtaAssociationLawProps {
  experts?: User[];
  learnMoreOnly?: boolean;
}

const defaultExperts = [
  {
    id: "scherbarth",
    databaseId: 2,
    slug: "scherbarth",
    name: "Hubertus Scherbarth",
    firstName: "Hubertus",
    lastName: "Scherbarth",
    additionalInfo: {
      jobTitle: "Rechtsanwalt, Steuerberater",
    },
    description: "",
    avatar: {
      url: "https://cms.resolvio.de/wp-content/uploads/2024/04/csm_scherbarth-profile_9561484b4a-1.jpg",
    },
  },
  {
    id: "moritz",
    databaseId: 14,
    slug: "moritz",
    name: "Moritz Riehl",
    firstName: "Moritz",
    lastName: "Riehl",
    additionalInfo: {
      jobTitle: "Rechtsanwalt",
    },
    description: "",
    avatar: {
      url: "https://cms.resolvio.de/wp-content/uploads/2024/03/csm_riehl-profile_d7e975b10c-1.jpg",
    },
  },
];

const RVOCtaAssociationLaw = ({
  experts,
  learnMoreOnly,
}: RVOCtaAssociationLawProps): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const ctaExperts = experts || defaultExperts;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(2),
        marginBottom: theme.spacing(2),
      }}
    >
      <Typography variant={!learnMoreOnly ? "body2" : "body1"}>
        <HtmlFromLangFile contentContainingHtml={t("associationLaw.claim")} />
      </Typography>

      <AvatarGroup
        max={3}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {ctaExperts.reverse().map((expert) => {
          return (
            <Tooltip
              placement="left"
              key={expert.id}
              title={
                <Grid
                  container={true}
                  direction="column"
                  style={{
                    gap: theme.spacing(0.2),
                    padding: theme.spacing(1),
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" align="center">
                    {expert.name}
                  </Typography>

                  <Typography variant="h5" align="center">
                    {expert.additionalInfo?.jobTitle}
                  </Typography>
                </Grid>
              }
            >
              <Avatar
                component={motion.button}
                whileHover={{
                  scale: 1.2,
                  transition: { duration: 0.2 },
                }}
                alt={expert.name}
                src={
                  expert.databaseId === 2
                    ? "https://www.kunzrechtsanwaelte.de/fileadmin/_processed_/d/2/csm_scherbarth-profile_9561484b4a.jpg"
                    : expert.avatar?.url
                }
                style={{ height: theme.spacing(4), width: theme.spacing(4) }}
              />
            </Tooltip>
          );
        })}

        <Avatar />

        <Avatar />

        <Avatar />

        <Avatar />

        <Avatar />

        <Avatar />

        <Avatar />
      </AvatarGroup>

      <Typography variant={!learnMoreOnly ? "body2" : "body1"}>
        <HtmlFromLangFile contentContainingHtml={t("associationLaw.claim2")} />
      </Typography>

      <Box
        style={{
          gap: theme.spacing(1),
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!learnMoreOnly && (
          <RVOTypeformPopupButton
            typeformId="vLVdPQqN"
            label={t("associationLaw.cta")}
          />
        )}
      </Box>
    </Box>
  );
};

export default RVOCtaAssociationLaw;
