import { Box, Typography, useTheme } from "@material-ui/core";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from "react-i18next";
import { rvoScrollbarStyles } from "../../../helpers/styling/rvoScrollbarStyles";
import { Category } from "../../../interfaces/Blog";
import { User } from "../../../interfaces/User";
import RVOTableOfContents from "../../02-molecules/RVOTableOfContents/RVOTableOfContents";
import RVOCtaAssociationLaw from "../RVOCtaAssociationLaw/RVOCtaAssociationLaw";
import RVOCtaEmployeeParticipation from "../RVOCtaEmployeeParticipation/RVOCtaEmployeeParticipation";
import { useRVOArticleTextStyles } from "./RVOArticleTextStyles";
import RVOLegalTypeChips from "./RVOLegalTypeChips";
import { makeCategoryTree } from "./makeCategoryTree";

export interface TreeBranch {
  name: string | undefined;
  slug: string | undefined;
  id: string | undefined;
  child?: TreeBranch;
}

export interface TreeRoot {
  id: string;
  children: TreeBranch[];
  name: string;
  slug: string;
}

interface RVOArticleSidebarProps {
  ctaMitarbeiterbeteiligung: boolean;
  ctaVereinsrecht: boolean;
  authors: User[];
  legalTypes?: Category[];
}

const RVOArticleSidebar = ({
  ctaMitarbeiterbeteiligung,
  ctaVereinsrecht,
  authors,
  legalTypes,
}: RVOArticleSidebarProps): JSX.Element => {
  const classes = useRVOArticleTextStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const legalTypesTree = makeCategoryTree(legalTypes || []);

  const legalTypesLvl1 = legalTypesTree.map((legalType) => {
    if (legalType?.name) {
      return legalType.name;
    }
    return undefined;
  });

  const legalTypesLvl2 = legalTypesTree.map((legalType) => {
    if (legalType?.children) {
      return legalType?.children.map((child) => {
        return child.name;
      });
    }
    return [];
  });

  const legalTypesLvl3 = legalTypesTree.map((legalType) => {
    if (legalType?.children) {
      return legalType?.children.map((child) => {
        return child?.child?.name;
      });
    }
    return [];
  });

  return (
    <Box
      className={classes.sidebar}
      display="flex"
      flexDirection="column"
      style={{
        gap: theme.spacing(0.5),
        maxHeight: "95vh",
        overflow: "auto",
        ...rvoScrollbarStyles(theme),
        paddingBottom: theme.spacing(4),
      }}
    >
      {/* The height of the following Box is measured to calculate the maximum height of the Table of Contents  */}

      <Box
        display="flex"
        flexDirection="column"
        style={{ gap: theme.spacing(0.8) }}
      >
        <Box
          display="flex"
          flexDirection="column"
          style={{ gap: theme.spacing(0.2) }}
        >
          {legalTypesLvl1[0] && (
            <Box>
              {/* <Typography variant="caption">
                  {t("wissen.jurisdiction")}
                </Typography> */}

              <RVOLegalTypeChips
                legalTypes={legalTypesLvl1}
                color={theme.palette.warning.dark}
              />
            </Box>
          )}

          {legalTypesLvl2.flat()[0] && (
            <Box>
              <Typography variant="caption">{t("wissen.legalType")}</Typography>

              <RVOLegalTypeChips
                legalTypes={legalTypesLvl2}
                color={theme.palette.success.main}
              />
            </Box>
          )}

          {legalTypesLvl3.flat()[0] && (
            <Box>
              <Typography variant="caption">{t("wissen.boardType")}</Typography>

              <RVOLegalTypeChips
                legalTypes={legalTypesLvl3}
                color={theme.palette.primary.main}
              />
            </Box>
          )}
        </Box>

        <Box style={{ marginTop: theme.spacing(1) }}>
          {ctaMitarbeiterbeteiligung ? (
            <RVOCtaEmployeeParticipation experts={authors} />
          ) : null}

          {ctaVereinsrecht ? <RVOCtaAssociationLaw experts={authors} /> : null}
        </Box>
      </Box>

      <Box>
        <Typography variant="caption">{t("wissen.tableOfContents")}</Typography>

        <RVOTableOfContents />
      </Box>
    </Box>
  );
};

export default RVOArticleSidebar;
