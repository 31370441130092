import { Chip, alpha } from "@material-ui/core";
import { important } from "@nvon/react-toolbox";
import theme, { fontWeightBold } from "../../../layouts/theme";

interface RVOCategoryChipProps {
  slug?: string;
  label: string;
  color?: string;
  style?: React.CSSProperties;
}

const RVOCategoryChip = (props: RVOCategoryChipProps): JSX.Element => {
  return (
    <Chip
      label={props.label}
      style={{
        ...theme.typography.caption,
        borderRadius: important("0  20px 20px 0"),
        backgroundColor: props.color && alpha(props.color, 0.2),
        color: props.color,
        fontWeight: fontWeightBold,
        ...props.style,
      }}
    />
  );
};

export default RVOCategoryChip;
