import { NoSsr } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import RVOBlogCard from "../RVOBlogCard/RVOBlogCard";
import { RVORelatedPostSliderProps } from "./RVORelatedPostSliderInterfaces";
import { useRVORelatedPostSliderStyles } from "./RVORelatedPostSliderStyles";
import "./slick-theme.css";

const RVORelatedPostSlider = ({
  similarPosts,
  settings,
  breakpointsAvailable,
}: RVORelatedPostSliderProps): JSX.Element => {
  const classes = useRVORelatedPostSliderStyles({ breakpointsAvailable });
  return (
    <NoSsr>
      <Slider {...settings} className={classes.slider}>
        {similarPosts.map((blog) => {
          return <RVOBlogCard key={blog.id} blog={blog} />;
        })}
      </Slider>
    </NoSsr>
  );
};

export default RVORelatedPostSlider;
