import { useMediaQuery } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import theme from "../layouts/theme";

export const useCurrentMuiBreakpoint = <DefaultValueType>(
  defaultValue: DefaultValueType
): Breakpoint | DefaultValueType => {
  const breakpoints: Record<Breakpoint, boolean> = {
    xs: useMediaQuery(theme.breakpoints.only("xs")),
    sm: useMediaQuery(theme.breakpoints.only("sm")),
    md: useMediaQuery(theme.breakpoints.only("md")),
    lg: useMediaQuery(theme.breakpoints.only("lg")),
    xl: useMediaQuery(theme.breakpoints.only("xl")),
  };

  const breakPoint = Object.entries(breakpoints).find(
    ([, isActive]) => isActive
  ) as [Breakpoint, boolean] | undefined;

  return breakPoint?.[0] || defaultValue;
};
