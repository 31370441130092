import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { ReactNode } from "react";
import { Category } from "../../../interfaces/Blog";
import { User } from "../../../interfaces/User";
import RVOArticleSidebar from "./RVOArticleSidebar";
import { useRVOArticleTextStyles } from "./RVOArticleTextStyles";

interface RVOArticleTextProps {
  children: ReactNode;
  ctaMitarbeiterbeteiligung: boolean;
  ctaVereinsrecht: boolean;
  authors: User[];
  legalTypes?: Category[];
}

const RVOArticleText = ({
  children,
  ctaMitarbeiterbeteiligung,
  ctaVereinsrecht,
  authors,
  legalTypes,
}: RVOArticleTextProps): JSX.Element => {
  const classes = useRVOArticleTextStyles();
  const theme = useTheme();

  if (useMediaQuery(theme.breakpoints.down("sm"))) {
    return <Typography className={classes.text}>{children}</Typography>;
  }

  return (
    <Grid container={true} spacing={5} className={classes.wrapper}>
      <Grid item={true} xs={12} sm={8} md={9}>
        <Typography className={classes.text}>{children}</Typography>
      </Grid>

      <Grid item={true} xs={1} sm={4} md={3}>
        <RVOArticleSidebar
          ctaMitarbeiterbeteiligung={ctaMitarbeiterbeteiligung}
          authors={authors}
          legalTypes={legalTypes}
          ctaVereinsrecht={ctaVereinsrecht}
        />
      </Grid>
    </Grid>
  );
};

export default RVOArticleText;
