import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { rvoSliderStyles } from "../../../helpers/styling/rvoSliderStyles";

export const useRVORelatedPostSliderStyles = makeStyles((theme: Theme) =>
  createStyles({
    slider: {
      ...rvoSliderStyles(theme),
      opacity: ({
        breakpointsAvailable: isLoaded,
      }: {
        breakpointsAvailable: boolean;
      }): number => (isLoaded ? 1 : 0),
    },
  })
);
