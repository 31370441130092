import { useEffect, useState } from "react";

type HeadingType = { id: string; text: string; level: number };
export function useHeadings(): HeadingType[] {
  const [headings, setHeadings] = useState<HeadingType[]>([]);
  useEffect(() => {
    const elements = Array.from(document.querySelectorAll("h2, h3"))
      .filter((element) => element.id)
      .map((element) => ({
        id: element.id,
        text: element.textContent ?? "",
        level: Number(element.tagName.substring(1)),
      }));
    setHeadings(elements);
  }, []);
  return headings;
}
