import { createStyles, makeStyles } from "@material-ui/core";
import { important } from "@nvon/react-toolbox";
import { headerHeight } from "../../../helpers/clientSide/constants";
import theme from "../../../layouts/theme";

import { rvoSliderStyles } from "../../../helpers/styling/rvoSliderStyles";

export const useRVOArticleTextStyles = makeStyles(() =>
  createStyles({
    sidebar: {
      position: "sticky",
      top: headerHeight,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      paddingTop: theme.spacing(1),
    },

    wrapper: {
      padding: `0 ${theme.spacing(10)}`,
      [theme.breakpoints.only("xl")]: {
        padding: `0 ${theme.spacing(2)}`,
      },
      [theme.breakpoints.only("lg")]: {
        padding: `0 ${theme.spacing(1)}`,
      },
      [theme.breakpoints.only("md")]: {
        padding: `0 ${theme.spacing(2)}`,
      },
      [theme.breakpoints.only("sm")]: {
        padding: `0 ${theme.spacing(1)}`,
      },
      [theme.breakpoints.only("xs")]: {
        marginTop: theme.spacing(1),
        padding: 0,
      },
    },

    text: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      color: theme.palette.primary.dark,

      "& > p, & > h2, & > h3, & > h4, & > ol, & > ul": {
        color: theme.palette.primary.dark,
        "& a:link, & a:visited, & a:active": {
          textDecoration: "underline",
          color: theme.palette.primary.dark,
        },
        "& a:hover": {
          color: theme.palette.primary.light,
          textDecoration: "underline",
        },
      },
      "& > h2": {
        fontSize: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingTop: important(`calc(${headerHeight}px + ${theme.spacing(3)})`),
        marginTop: -headerHeight,
      },
      "& > h3": {
        fontSize: theme.spacing(1.5),
        paddingBottom: theme.spacing(1),
        paddingTop: important(`calc(${headerHeight}px + ${theme.spacing(3)})`),
        marginTop: -headerHeight,
      },
      "& > h4": {
        marginTop: `${theme.spacing(3)} !important`,
        marginBottom: theme.spacing(2),
      },
      "& > h5": {
        marginTop: `${theme.spacing(3)} !important`,
        marginBottom: theme.spacing(2),
      },
      "& > p, & > ul, & ol": {
        fontSize: theme.spacing(1.2),
      },
      "& > ul li, & > ol li": {
        marginBottom: theme.spacing(1),
      },
      "& ul > li": {
        paddingLeft: theme.spacing(0.3),
      },
      "& ol > li": {
        paddingLeft: theme.spacing(0.6),
      },
      "& ul": {
        listStyle: "square",
      },
      "& ul, & ol": {
        margin: theme.spacing(1.5, 0),
        paddingInlineStart: theme.spacing(1.3),
      },
      "& ul ol, & ol ul": { margin: theme.spacing(1, 0) },
    },
    slider: {
      ...rvoSliderStyles(theme),
    },
  })
);
