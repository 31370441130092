import { Box, useTheme } from "@material-ui/core";
import { uniq } from "lodash-es";
import RVOCategoryChip from "../RVOCategoryChip/RVOCategoryChip";

interface RVOLegalTypeChipsProps {
  legalTypes: (string | undefined)[] | (string | undefined)[][];
  color: string;
}

const RVOLegalTypeChips = ({
  legalTypes,
  color,
}: RVOLegalTypeChipsProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Box>
      {uniq(legalTypes.flat().sort()).map((legalType) => {
        if (legalType) {
          return (
            <RVOCategoryChip
              key={legalType}
              label={legalType}
              color={color}
              style={{
                marginRight: theme.spacing(0.2),
                marginBottom: theme.spacing(0.2),
              }}
            />
          );
        }
        return null;
      })}
    </Box>
  );
};

export default RVOLegalTypeChips;
